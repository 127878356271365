var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-wrapper"},[_c('button',{class:{
			'active': _vm.isCardListSelected,
			'disabled': _vm.isCardListDisabled,
		},attrs:{"disabled":_vm.isCardListDisabled},on:{"click":function($event){return _vm.selectTab('cardList', $event)}}},[_vm._v(" Usar cartão salvo ")]),_c('div',{class:{
			'spacer': true,
			'inactive-left': _vm.isCardFormSelected,
			'inactive-right': _vm.isCardListSelected,
		}}),_c('button',{class:{
			'active': _vm.isCardFormSelected,
		},on:{"click":function($event){return _vm.selectTab('cardForm', $event)}}},[_vm._v(" Inserir dados do cartão ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }