import { render, staticRenderFns } from "./CartaoList.vue?vue&type=template&id=0f8e62a7&scoped=true"
import script from "./CartaoList.vue?vue&type=script&lang=js"
export * from "./CartaoList.vue?vue&type=script&lang=js"
import style0 from "./CartaoList.vue?vue&type=style&index=0&id=0f8e62a7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f8e62a7",
  null
  
)

export default component.exports